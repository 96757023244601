import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.goods_base_url)


// 商品数据 - 接口列表
const apis = {
  // 商品列表
  goodsList: '/yijiajia-item/page',
  // 商品新增
  addGoods: '/yijiajia-item/save',
  // 商品详情
  goodsDetail: '/yijiajia-item/detail', 
  // 根据商品查询单品列表
  singleListByGoodsId: '/yijiajia-item/listByItemId',
  // 商品修改
  updateGoods: '/yijiajia-item/update',
  // 删除商品
  removeGoods: '/yijiajia-item/remove',
  // 商品列表及下级单品
  goodsAndsingleList: '/yijiajia-sub-item/page',
  // 单品详情
  singleDetail: '/yijiajia-sub-item/quoteDetail',
}

/**
 * 商品列表
*/
export function goodsList (params) {
  return request({
    url: apis.goodsList,
    method: 'get',
    params
  })
}

/**
 * 商品新增
*/
export function addGoods (data) {
  return request({
    url: apis.addGoods,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 商品修改
*/
export function updateGoods (data) {
  return request({
    url: apis.updateGoods,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 商品详情
*/
export function goodsDetail (params) {
  return request({
    url: apis.goodsDetail,
    method: 'get',
    params
  })
}

/**
 * 根据商品查询单品列表
*/
export function singleListByGoodsId (params) {
  return request({
    url: apis.singleListByGoodsId,
    method: 'get',
    params
  })
}

/**
 * 删除商品
*/ 
export function removeGoods (data) {
  return request({
    url: apis.removeGoods,
    method: 'post',
    data,
  })
}

/**
 * 商品列表及下级单品
*/
export function goodsAndsingleList (params) {
  return request({
    url: apis.goodsAndsingleList,
    method: 'get',
    params
  })
}


/**
 * 单品详情
*/
export function singleDetail (params) {
  return request({
    url: apis.singleDetail,
    method: 'get',
    params
  })
}




/**
 * 颜色列表 
 */
export function colorList () {
  return request({
    url: '/color',
    method: 'get'
  })
}

/**
 * 新增颜色
 * @param {object} parameter 
 */
export function addColor (parameter) {
  return request({
    url: '/color',
    method: 'post',
    data: parameter
  })
}

/**
 * 更新颜色
 * @param {object} parameter 
 */
export function updateColor (parameter) {
  return request({
    url: '/color',
    method: 'put',
    data: parameter
  })
}

/**
 * 删除颜色
 * @param {object} parameter 
 */
export function deleteColor (parameter) {
  return request({
    url: '/color',
    method: 'delete',
    data: parameter
  })
}

/**
 * 颜色排序
 * @param {object} parameter 
 */
export function colorSort (parameter) {
  return request({
    url: '/color-sort',
    method: 'post',
    data: parameter
  })
}

/**
 * 尺码列表 
 */
 export function sizeList () {
  return request({
    url: '/size',
    method: 'get'
  })
}

/**
 * 新增尺码
 * @param {object} parameter 
 */
export function addSize (parameter) {
  return request({
    url: '/size',
    method: 'post',
    data: parameter
  })
}

/**
 * 更新尺码
 * @param {object} parameter 
 */
export function updateSize (parameter) {
  return request({
    url: '/size',
    method: 'put',
    data: parameter
  })
}

/**
 * 删除尺码
 * @param {object} parameter 
 */
export function deleteSize (parameter) {
  return request({
    url: '/size',
    method: 'delete',
    data: parameter
  })
}

/**
 * 尺码排序
 * @param {object} parameter 
 */
export function sizeSort (parameter) {
  return request({
    url: '/size-sort',
    method: 'post',
    data: parameter
  })
}

/**
 * 获取企业列表
 * 联想选择框
 */
export function companyList (parameter) {
  return request({
    url: '/company',
    method: 'get',
    params: parameter
  })
}

/**
 * 获取品牌列表
 * 联想选择框
 */
 export function brandList (parameter) {
  return request({
    url: '/brand',
    method: 'get',
    params: parameter
  })
}


