import createRequest from '@/utils/request'
import API_PREFIX from '@/config/api.prefix'
// @params:  baseUrl, accessKey, secretKey
const request = createRequest(API_PREFIX.tag_base_url)


// 用户数据、管理员数据 - 接口列表
const apis = {
  // 标签库列表
  listTag: '/yijiajia-tag/listTag',
  // 标签树： 标签库里的标签
  listTree: '/yijiajia-tag/listTree',
  // 新增标签
  saveTag: '/yijiajia-tag/save',
  // 获取标签详情
  tagDetail: '/yijiajia-tag/detail',
  // 获取路径
  getPath: '/yijiajia-tag/getPath',
  // 修改标签
  updateTag: '/yijiajia-tag/update',

  // 删除标签
  removeTag: '/yijiajia-tag/remove',

  // 标签合并
  mergeTag: '/yijiajia-tag/merge',

  // 结构化标签
  structureTag: '/yijiajia-tag/structured',


  // 搜索标签
  searchTag: '/yijiajia-tag/search'

}

// 标签库列表
export function listTag(parameter) {
  return request({
    url: apis.listTag,
    method: 'get',
    params: parameter
  })
}

// 标签树： 标签库里的标签
export function listTree(parameter) {
  return request({
    url: apis.listTree,
    method: 'get',
    params: parameter
  })
}

// 新增标签
export function saveTag(parameter) {
  return request({
    url: apis.saveTag,
    method: 'post',
    data: parameter
  })
}

// 获取标签详情
export function tagDetail (tagId) {
  return request({
    url: apis.tagDetail + `/${tagId}`,
    method: 'get'
  })
}

// 获取路径 
export function getPath (parameter) {
  return request({
    url: apis.getPath,
    method: 'get',
    params: parameter
  })
}

// 修改标签 
export function updateTag (parameter) {
  return request({
    url: apis.updateTag,
    method: 'post',
    data: parameter
  })
}

// 删除标签
export function removeTag (parameter) {
  return request({
    url: apis.removeTag,
    method: 'post',
    data: parameter
  })
}

// 标签合并
export function mergeTag (parameter) {
  return request({
    url: apis.mergeTag,
    method: 'post',
    data: parameter
  })
}

// 结构化标签
export function structureTag (parameter) {
  return request({
    url: apis.structureTag,
    method: 'post',
    data: parameter
  })
}

// 搜索标签
export function searchTag (parameter) {
  return request({
    url: apis.searchTag,
    method: 'get',
    params: parameter
  })
}

