<template>
  <div>
    <div ref="editor"></div>
    <!-- <button v-on:click="getContent">查看内容</button> -->
  </div>
</template>

<script>
  import Editor from 'wangeditor'
  import {uploadFileApi} from '@/api/upload'
  import {getSign, createNonce} from '@/utils/tools'
  import apiKeys from '@/config/api.key'
  import { buildThumborUrl } from '@/utils/tools'
  import axios from 'axios'

  function createHeaders() {
    // 上传header参数预处理
    let timestamp = Date.now()
    let nonce = createNonce()
    let signObj = {
      'X-validate-accessKey': apiKeys.accessKey,
      'X-validate-timestamp': timestamp,
      'X-validate-nonceStr': nonce
    }
    let sign = getSign(signObj, apiKeys.secretKey)

    return {
      'X-validate-accessKey': apiKeys.accessKey,
      'X-validate-timestamp': timestamp,
      'X-validate-nonceStr': nonce,
      'X-validate-sign': sign,
    }
  }
  export default {
    name: 'editor',
    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      bucketName: {
        type: String,
        required: true
      },
      dataLoaded: {
        type: Boolean,
        default: false
      },
      dataUpdated: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        editorContent: '',
        editor: null
      }
    },
    watch: {
      dataLoaded: function(val) {
        if (val && this.editor) {
          this.editor.txt.html(this.value)
        }
      },
      dataUpdated: function(val) {
        if (val) {
          this.editor.txt.html(this.value)
        }
      }
    },
    methods: {
      getContent() {
        // 通过代码获取编辑器内容
        let data = this.editor.txt.html()
        alert(data)
      },
    },
    mounted() {
      console.log(this.bucketName)
      let editor = new Editor(this.$refs.editor)
      let self = this

      editor.config.zIndex = 50

      // 设置高度
      editor.config.height = 360

      // 取消自动 focus
      editor.config.focus = false

      // 配置 server 接口地址
      console.log(uploadFileApi)
      editor.config.uploadImgServer = uploadFileApi

      // 默认限制图片大小是 5M ，可以自己修改。
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024 // 5M

      // 默认配置  ['jpg', 'jpeg', 'png', 'gif', 'bmp']， 若不限制 则配置为 []
      editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp']

      // 限制图片上传张数，默认为 100 张，需要限制可自己配置。
      editor.config.uploadImgMaxLength = 50 // 一次最多上传 5 个图片

      // 自定义filename
      editor.config.uploadFileName = 'files'

      // 自定义上传参数
      editor.config.uploadImgParams = {
        bucketName: this.bucketName  // yijiajia-company-license
      }

      // 自定义header
      editor.config.uploadImgHeaders = createHeaders()

      // timeout 即上传接口等待的最大时间，默认是 10 秒钟，可以自己修改。
      editor.config.uploadImgTimeout = 20 * 1000

      editor.config.uploadImgHooks = {
        // 上传图片之前
        before: function(xhr, a, b) {
          console.log(xhr, a, b)
          
          let headers = createHeaders()
          self.editor.config.uploadImgHeaders = {
            ...headers
          }

          // 可阻止图片上传
          // return {
          //   prevent: true,
          //   msg: '需要提示给用户的错误信息'
          // }
        },
        // 图片上传并返回了结果，图片插入已成功
        success: function(xhr) {
          console.log('success', xhr)
        },
        // 图片上传并返回了结果，但图片插入时出错了
        fail: function(xhr, editor, resData) {
          console.log('fail', resData)
        },
        // 上传图片出错，一般为 http 请求的错误
        error: function(xhr, editor, resData) {
          console.log('error', xhr, resData)
        },
        // 上传图片超时
        timeout: function(xhr) {
          console.log('timeout')
        },
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function(insertImgFn, result) {
          // result 即服务端返回的接口
          console.log('customInsert', result)
          let url = buildThumborUrl(result.data[0])
          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          insertImgFn(url)
        }
      }
      editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        this.$showLoading()
        console.log(resultFiles)
        let formData = new FormData()
        formData.append('bucketName', this.bucketName)
        for (let i =0; i < resultFiles.length; i ++ ) {
          formData.append('files', resultFiles[i])
        }
        let headers = createHeaders()
        axios({
          url: uploadFileApi,
          method: 'post',
          headers: {
            'Content-Type':'multipart/form-data',
            ...headers
          },
          data: formData
        }).then(res => {
          this.$hideLoading()
          console.log(res.data)
          if (res.data.code == 200) {
            let results = res.data.data
            for (let i = 0; i < results.length; i ++) {
              let url = buildThumborUrl(results[i])
              insertImgFn(url)
            }
          }
        }).catch(err => {
          this.$hideLoading()
          console.error(err)
        })
          

          // 上传图片，返回结果，将图片插入到编辑器中
          // insertImgFn(imgUrl)
      }


      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.editorContent = newHtml
        this.$emit('change', this.editorContent)
        // this.$emit('input', this.editorContent)
      }

      editor.create()

      // 设置初始内容
      if (this.value) {
        editor.txt.html(this.value)
      }

      this.editor = editor
    },
    beforeDestroy() {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    }
  }
</script>

<style scoped>
.w-e-text-container {
    height: auto;
}
</style>